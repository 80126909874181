/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, TitleMain, Title, Text, Image, Generic } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--30 pl--40 pr--40 pt--30" menu={true}>
            
            <Menu className="--full pb--06 pl--06 pr--06 pt--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Subtitle className="subtitle-box subtitle-box--invert" content={"23—6—2021"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10 pr--10" innerClassName="pl--0 pr--0" href={"#informace"} target={""} content={"Místo a čas"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10 pr--10" innerClassName="pl--0 pr--0" href={"/svatebni-dary"} target={""} content={"Svatební dary"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10" innerClassName="pl--0 pr--0" href={"/potvrdit-ucast"} target={""} content={"Potvrdit účast"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-c583e9 js-anim  --anim7 --anim-s5 bg--bottom --full" anim={"7"} name={"uvod"} animS={"5"} border={null} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pb--80 pt--80" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <TitleMain className="title-box fs--36 mt--10" content={"Svatební web"}>
              </TitleMain>

              <Title className="title-box fs--102 w--300 title-box--invert lh--12 mt--08" content={"Martina &amp; Ondřej"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"23—6—2021, Brno"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--50" name={"informace"} style={{"backgroundColor":"rgba(16, 16, 18, 1)"}} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300 title-box--invert" content={"Místo a čas obřadu"}>
              </Title>

              <Text className="text-box" content={"23.6.2021<br>"}>
              </Text>

              <Text className="text-box" content={"13:00"}>
              </Text>

              <Text className="text-box lh--14" style={{"maxWidth":278}} content={"Místo a čas konání upřesníme zde na našem svatebním webu, ale bude to v Brně :-)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image className="--shape2" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/f63180fa55494dcc8ab7c6bc1cef8e1d_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/f63180fa55494dcc8ab7c6bc1cef8e1d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/f63180fa55494dcc8ab7c6bc1cef8e1d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/f63180fa55494dcc8ab7c6bc1cef8e1d_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/f63180fa55494dcc8ab7c6bc1cef8e1d_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/11212/f63180fa55494dcc8ab7c6bc1cef8e1d_s=2000x_.jpeg 2000w"} content={null} position={{"x":"-36.134453781512605%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Generic name={"umxq7smcuqb"} style={{"right":"0.5em","bottom":"0.5em","margin":"1em","zIndex":"12","display":"block","padding":"1vw 1.25vw","position":"fixed","boxShadow":"0 3px 12px -1px rgba(0,96,217,.15), 0 0 0 2px rgba(0,96,217,.04)","boxSizing":"border-box","background":"#1c222f","borderRadius":"0.25vw"}} TagName={"div"} content={"<a class='text-box w--700 ff--2 pr--20' style='display: -ms-flexbox!important; display: flex!important; -ms-flex-align: center; align-items: center; color: #fff;' href='https://saywebpage.com/cs/moderni-svatebni-oznameni/'><svg style='width: 1.75em; height: 1.75em; margin-right: 1.25em;' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 76.54 76.54'><circle cx='38.27' cy='38.27' r='37.07' fill='#0060d9'/><path fill='#ffffff' d='M37.83 52.52a2 2 0 01-1.42-3.42l10.84-10.83-10.84-10.84a2 2 0 112.83-2.83L51.5 36.85a2 2 0 010 2.83L39.24 51.93a2 2 0 01-1.41.59z'/><path fill='#ffffff' d='M50.08 40.27H26.46a2 2 0 010-4h23.62a2 2 0 010 4z'/></svg><h3 class='text pointer'>Vytvořit vlastní svatební web</h3></a>"} RootClassName={""}>
        </Generic>


        <Column className="pb--50 pt--25" name={"informace-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=2000x_.jpeg 2000w"} content={null} position={{"x":"-90.53501400560225%","y":"-2.1008403361344534%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300 title-box--invert" style={{"maxWidth":512}} content={"Místo a čas recepce"}>
              </Title>

              <Text className="text-box" content={"23.6.2021<br>"}>
              </Text>

              <Text className="text-box" content={"15:00<br>"}>
              </Text>

              <Text className="text-box lh--14" style={{"maxWidth":290}} content={"Sledujte náš svatební web! Program svatby budeme aktualizovat. Zatím se můžete podívat na fotky z našeho zásnubího focení."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-15bo4wv --center --parallax pb--80 pt--80" name={"informace-3"} style={{"backgroundColor":"rgba(0,0,0,1)"}} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/11212/c9799baaee2045f1899a4aeb666642b7_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pb--40 pt--40" anim={"6"} animS={"5"}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62 btn-box--invert" innerClassName="mt--10 mb--10" href={"/fotogalerie"} content={"Fotogalerie"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62 btn-box--invert" innerClassName="mt--10 mb--10" href={"/svatebni-dary"} content={"Svatební dary"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--62" innerClassName="mt--10 mb--10" href={"potvrdit-ucast"} content={"Potvrdit účast"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informace-4"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--center w--300" content={"<span style=\"color: rgb(0, 0, 0);\">Jak probíhají přípravy?</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"maxWidth":416}} content={"Zde na svatebním webu naleznete harmonogram svatby, datum a místo konání, seznam darů ale i náhled do zákulisí příprav!&nbsp;Místo a čas konání, dress code, seznam dárků a program svatby zveřejníme již brzy!<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1" href={"/informace"} content={"Nahlédnou do příprav"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/d3350649fbd34703b92f148cf574a381_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/d3350649fbd34703b92f148cf574a381_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/11212/d3350649fbd34703b92f148cf574a381_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/11212/d3350649fbd34703b92f148cf574a381_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/11212/d3350649fbd34703b92f148cf574a381_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/11212/d3350649fbd34703b92f148cf574a381_s=2000x_.jpeg 2000w"} content={null} position={{"x":"-48.17927170868347%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-3dpieh --center bg--center-h bg--bottom --parallax pb--80 pt--80" name={"informace-5"} style={{"backgroundColor":"rgba(0,0,0,1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/3fba30cf38984f718634b332a5029b0b_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left mb--80 mt--80">
              
              <Title className="title-box title-box--center fs--86" content={"Těšíme se na Vás!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" style={{"maxWidth":554}} content={"Sledujte náš svatební web pro veškeré aktualizace! Již brzy doplníme seznam svatebních darů."}>
              </Text>

              <Text className="text-box text-box--center mt--20" style={{"maxWidth":554}} content={"<a href=\"https://saywebpage.com/cs/svatebni-web/\" style=\"color: rgb(255, 255, 255);\">Svatební web</a>&nbsp;— vytvořeno na Saywebpage."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}